import GlobalStyle from '../foundations/styles/GlobalStyle';
import React, { useEffect, useRef } from 'react';
import {
    atom,
    RecoilRoot,
} from 'recoil';
// import InterPreloader from '../foundations/InterPreloader';

interface Props {
    children: any;
}

export const ThemeContext = React.createContext<{
    registerUpdatePageFunction: (updateFunction: () => void) => void,
    unregisterUpdatePageFunction: () => void,
}>({ registerUpdatePageFunction: () => { }, unregisterUpdatePageFunction: () => { } });


export type PageStateType = {
    isLoaded: boolean,
    menuColor: string,
    showPreloader: false
}

export type WebStateType = {
    isLoaded: boolean
}

export const webState = atom<WebStateType>({
    key: 'webState',
    default: {
        isLoaded: false
    },
});

export const pageState = atom<PageStateType>({
    key: 'pageState',
    default: {
        isLoaded: false,
        showPreloader: false,
        menuColor: "white"
    },
});

const GlobalProvider: React.FC<Props> = (props) => {
    const updateFunctionRef = useRef<(() => void)[]>([]);

    useEffect(() => {
        update();
    }, [])

    const getUpdateFunction = () => {
        return updateFunctionRef.current
    }

    const update = () => {
        const func = getUpdateFunction();
        if (func.length > 0) {
            func.forEach((fun) => fun())
        }
        window.requestAnimationFrame(update)
    }

    const registerUpdatePageFunction = (updateFunction: () => void) => {
        updateFunctionRef.current.push(updateFunction);
    }

    const unregisterUpdatePageFunction = () => {
        updateFunctionRef.current = [];
    }

    return (
        <ThemeContext.Provider value={{ registerUpdatePageFunction, unregisterUpdatePageFunction }}>
            <GlobalStyle />
            <RecoilRoot>
                {props.children}
            </RecoilRoot>
        </ThemeContext.Provider>
    )
}

export default ({ element, props }: any) => <GlobalProvider {...props}>{element}</GlobalProvider>;