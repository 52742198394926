import zl_titles from "../../assets/fonts/zl_titles.woff";
import zl_paragraph from "../../assets/fonts/zl_paragraph.woff";
import { createGlobalStyle } from 'styled-components';
import { css } from 'styled-components';

export const fonts = {
  title: 'zl_titles, arial',
  paragraph: 'zl_paragraph, arial',
};

export const colors = {
  color_primary: '#ff1231',
  color_gray: '#6D7883',
  color_medium_gray: 'rgba(0, 0, 0, 0.60)',
  color_light_gray: 'rgba(0, 0, 0, 0.30)',
  color_light_light_gray: 'rgba(0, 0, 0, 0.10)',
  color_white: '#fff',
  color_light_white: 'rgba(255, 255, 255, 0.9)',
  color_lighter_white: 'rgba(255, 255, 255, 0.6)',
  color_black: '#000',
};

export const brakepoints = {
  mobile: '480px',
  tablet: '768px',
};

export const mediaQueryTablet = (style: any) => css`
  @media only screen and (max-width: ${brakepoints.tablet}) {
    ${style}
  }
`;

export const mediaQueryMobile = (style: any) => css`
  @media only screen and (max-width: ${brakepoints.mobile}) {
    ${style}
  }
`;

export const flexUnit = (
  amount: number,
  min: number,
  max: number,
  unit = 'vw',
  prop = 'font-size',
) => {
  const minBreakpoint = (min / amount) * 100;
  const maxBreakpoint = max ? (max / amount) * 100 : false;
  const dimension = unit === 'vw' ? 'width' : 'height';

  return `
      @media (max-${dimension}: ${minBreakpoint}px) {
        ${prop}: ${min}px;
      }

      ${
        max
          ? `
        @media (min-${dimension}: ${maxBreakpoint}px) {
          ${prop}: ${max}px;
        }
      `
          : ''
      }

      ${prop}: ${amount}${unit}
    `;
};

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: zl_titles;
        src: url(${zl_titles}) format('woff');
    }

    @font-face {
        font-family: zl_paragraph;
        src: url(${zl_paragraph}) format('woff');
    }

    html {
        /* overflow: hidden;
        position: fixed; */
    }

    body {
        font-family: ${fonts.paragraph};
        background: white;
        margin: 0;
        -webkit-font-smoothing: antialiased;
        /* overflow: hidden;
        position: fixed; */
        width: 100%;
        overflow-x: hidden;
    }

     ::-moz-selection {
        color: white;
        background: ${colors.color_primary};
    }

 ::selection {
     color: white;
     background: ${colors.color_primary};
 }
`;

export default GlobalStyle;
